import React from 'react';
import { graphql} from 'gatsby';
import CodeOfConduct from '../components/ExternalPage';
import {faceLogo} from '../constants/strings';

export const query = graphql`
    query{
        content: allGhostPage (
            filter: { 
                tags : { elemMatch : { name: { eq: "#code-of-conduct" }}}
            }
        ){
            nodes {
                html
            }
        }
    }
`;

const CodeOfConductPage = props => {
    let content = props.data.content.nodes[0].html;
    let heading1 = 'Fintech Association for Consumer Empowerment (“FACE”)';
    let heading2 = 'Ethical Code of Conduct (“Code of Conduct”)';
    return (
        <CodeOfConduct
            title={heading1}
            title2={heading2}
            data={content}
            logo={faceLogo}
        />
    )
}

export default CodeOfConductPage;